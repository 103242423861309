import { Component, Injectable, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/platform-browser';
import { DataService } from './data.service';
import { Modul } from './models/modul';

@Component({
    selector: 'my-app',
    host: {'window:beforeunload':'doSomething'},
    providers: [],
    template: `
        <router-outlet></router-outlet>
    `
})


@Injectable()
export class AppComponent implements OnInit{

    spinner = 'sk-fading-circle' ;
    title = '';

    constructor(private dataService: DataService) {
      //localStorage.setItem('modulewm_module', '');
    }

    
  async ngOnInit() {
    await this.dataService.init();
  }

    doSomething() {
      // console.log("refresh");
    }

    getModuleById(id: Number, module: Modul[]): Modul {
      for (let entry of module) {
          if (entry.id===id) {
            //console.log( "found1");
            this.spinner = '';
            return entry;
          }
          for (let subentry of entry.submodule) {
            if (subentry.id===id) {
              //console.log( "found2");
              this.spinner = '';
              return subentry;
            }
            for (let subsubentry of subentry.submodule) {
              if (subsubentry.id===id) {
              //console.log( "found3");
              this.spinner = '';
              return subsubentry;
            }
          }
        }
      }
      this.spinner = '';
      return null;
    }


}