import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/platform-browser';
import { GlobalEventsManager} from '../services/GlobalEventsManager';
import { Textblock } from '../models/textblock';
import { TextblockService } from '../textblock/textblock.service';
import { ComMaster } from '../models/commaster';
import { SharedService } from '../services/shared.service';
import { Metadata } from '../models/metadata';
import { DataService } from '../data.service';

@Component({
  selector: 'my-app',
  providers: [TextblockService],
  templateUrl: 'textblock.html',
  styleUrls: ['textblock.component.css']
})

export class TextblockComponent extends ComMaster implements OnInit {

  title = 'Ihre Ausgangslage und was Sie von dieser Unterlage erwarten dürfen';
  subtitle = '';
  ausgangslage = '';
  schwerpunkte = '';
  context = '';
  showTextblock: number = -1;

  constructor( private sharedService: SharedService, public dataService: DataService, @Inject(DOCUMENT) private document) {
    super(dataService);
  }

  ngOnInit() {
    this.context = this.getContext();
    this.modulemode = this.getModulemode();

    //this.globalEventsManager.showNavBar(this.context);
    this.dataService.setNavState(this.context);
    

    const metadata = Metadata.getMetadata(this.getContext());
    this.ausgangslage = metadata.ausgangslage;
    this.schwerpunkte = metadata.schwerpunkte;
    this.showTextblock = metadata.showTextblock;
  }

  public isChecked(check: number): String {
    if (check > 0) {
      return this.url + this.baseHref + "/" + this.gelberCheck;
    } else {
      return this.url + this.baseHref + "/" + this.leererCheck;
    }
  }

  simpleToggle(image: any): void {
    super.simpleToggle(image);
    if (image.src === this.url + this.baseHref + "/" +  this.leererCheck) {
      this.showTextblock = -1;
      this.ausgangslage = '';
      this.schwerpunkte = '';
    } else {
      this.showTextblock = 1;
    }
    this.storeText();
  }

  storeText(): void {

    const metadata: Metadata = Metadata.getMetadata(this.getContext());

    // console.log(metadata);
/*
    if (metadata === undefined) {

    }
*/
    metadata.ausgangslage = this.ausgangslage;
    metadata.schwerpunkte = this.schwerpunkte;
    metadata.showTextblock = this.showTextblock;
    metadata.storeMetadata(this.context);
  }
}



