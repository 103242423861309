import { Modul } from './modul';
import { Customerdata } from './customerdata';
import * as moment from 'moment';

export class Metadata {

    module: Modul[] = [];

    showTextblock: number = -1;
    ausgangslage = '';
    schwerpunkte = '';
    customerdata: Customerdata = new Customerdata;

    selectionModel: Modul[] = [];

    kontaktfolie = '';
    kontaktfolien: string[] = [];
    kontaktfolie_position = '';

    wm_english = '';
    wm_base = '';
    uk_base = '';
    ik_base = '';

    deckblatt = '';

    docurl = '';

    // Weiterempfehlungsseite einauen
    showWeiterempfehlungpage: number = -1;

    // Anlageberatung einauen
    showAnlageberatung: number = -1;

    // Kontaktseite einauen
    showContactpage: number = -1;

    public static getClearMetadata(context: string): Metadata {
        return new Metadata;
    }

    public static getMetadata(context: string): Metadata {

        let temp = '';

        temp = localStorage.getItem('metadata' + context);
     console.log('metadata temp ' + JSON.stringify(temp));
        if (temp != null && temp !== '') {
            // console.log('metadata temp for ' + context);
            const metadata: Metadata = new Metadata();
            const t = JSON.parse(temp);
            // console.log(t);
            metadata.module = t.module;

            metadata.wm_english = localStorage.getItem('wm_english');
            metadata.wm_base = localStorage.getItem('wm_base');
            metadata.uk_base = localStorage.getItem('uk_base');
            metadata.ik_base = localStorage.getItem('ik_base');

            metadata.showTextblock = t.showTextblock;
            metadata.ausgangslage = t.ausgangslage;
            metadata.schwerpunkte = t.schwerpunkte;

            if (t.customerdata === null) {
                metadata.customerdata.anrede = '';
                metadata.customerdata.titel = '';
                metadata.customerdata.kundenname = '';
                metadata.customerdata.beratername = '';
                metadata.customerdata.ortdesgespraechs = '';

            } else {
                metadata.customerdata = t.customerdata;
            }
            metadata.showWeiterempfehlungpage = t.showWeiterempfehlungpage;
            metadata.showAnlageberatung = t.showAnlageberatung;
            metadata.kontaktfolie_position = t.kontaktfolie_position;
            metadata.kontaktfolien = t.kontaktfolien;
            metadata.showContactpage = t.showContactpage;
            metadata.deckblatt = t.deckblatt;

            return metadata;
        } else {
            // console.log('metadata created ');
            const metadata: Metadata = new Metadata();
            return metadata;
        }
    }

    constructor() {
    }

    public storeMetadata(context: string): void {
        // console.log('metadata store: ' + context + ' - ' + JSON.stringify(this));
        localStorage.setItem('metadata' + context, JSON.stringify(this));
    }


    public getMetadataAsVBAString(context: string, language: string, presentationmode: string, docurl: string, contacturl: string): string {

        let returner = '';
        let folien = '';

        console.log(context + " " + presentationmode)

        if (context === 'wm' || context === 'pb' || context === 'english_wm') {

            if (presentationmode === 'modul') {
                folien = this.generateWMModule(contacturl, language);
            }

            if (presentationmode === 'thema') {
                folien = this.generateWMThema(contacturl);
            }

            if (presentationmode === 'english') {
                folien = this.generateWMEnglish(contacturl);
            }

        }

        if (context === 'uk') {

            if (presentationmode === 'modul') {
                folien = this.generateUKModule(contacturl);
            }

            if (presentationmode === 'thema' || presentationmode === 'english') {
                folien = this.generateUKThema(contacturl, presentationmode);
            }
        }

        if (context === 'ik') {
            folien = this.generateIK(language, contacturl);

        }

        // console.log(returner)
        return folien;

    }

    generateWMThema(contacturl: string): string {

        let folien = '';
        let agendathemen = '';
        let kundendaten = '';
        let disclaimer = '';
        const text = '';

        for (const entry of this.module) {
            agendathemen = agendathemen + '1|' + entry.name + ';';
            if (entry.link !== '') {
                const tempFolie = this.generatePPTLocation(entry, 'wm');
                folien = tempFolie + ';';
            }
        }

        // Kontakte Ende
        if ((this.kontaktfolie_position === 'start') || (this.kontaktfolie_position === 'end')) {
            for (const entry of this.kontaktfolien) {
                folien = folien + contacturl + entry + ';';
            }
        }
        // Weiterempfehlung
        if (this.showWeiterempfehlungpage > 0) {
            folien = folien + this.generateStandardLocation('Empfehlung_1.pptx', 'wm') + ';';
        }

        // Disclaimer
        folien = folien + this.generateStandardLocation('Disclaimer_1.pptx', 'wm') + ';';

        var ddg = '' + this.getFormattedDate(this.customerdata.datumdesgespraechs);
        if (this.customerdata.datumdesgespraechs === undefined) {
            ddg = '';
        }

        let titel = ';';
        if (this.customerdata.titel !== undefined) {
            titel = this.customerdata.titel + ';';
        }
        kundendaten = this.customerdata.anrede + ';' + titel + this.customerdata.kundenname + ';'
            + ddg + ';' + this.customerdata.ortdesgespraechs + ';'
            + this.customerdata.beratername + ';' + this.customerdata.wmstandort;

        return ('combookdata||0||' + folien + '||' + kundendaten + '||' + text + '||' + agendathemen + '||');

    }

    generateWMEnglish(contacturl: string): string {

        let folien = '';
        let agendathemen = '';
        let kundendaten = '';
        let disclaimer = '';
        const text = '';

        for (const entry of this.module) {
            agendathemen = agendathemen + '1|' + entry.name + ';';
            if (entry.link !== '') {
                const tempFolie = this.generatePPTLocation(entry, 'wm_english');
                folien = tempFolie + ';';
                //disclaimer = tempFolie.replace(".pptx", "_disclaimer.pptx");
            }
        }

        // Kontakte Ende
        if ((this.kontaktfolie_position === 'start') || (this.kontaktfolie_position === 'end')) {
            for (const entry of this.kontaktfolien) {
                folien = folien + contacturl + entry + ';';
            }
        }
        // Weiterempfehlung
        if (this.showWeiterempfehlungpage > 0) {
            folien = folien + this.generateStandardLocation('referral_1.pptx', 'wm_english') + ';';
        }

        // Disclaimer
        folien = folien + this.generateStandardLocation('Disclaimer_en_1.pptx', 'wm_english') + ';';

        var ddg = '' + this.getFormattedDate(this.customerdata.datumdesgespraechs);
        if (this.customerdata.datumdesgespraechs === undefined) {
            ddg = '';
        }

        let titel = ';';
        if (this.customerdata.titel !== undefined) {
            titel = this.customerdata.titel + ';';
        }
        kundendaten = this.customerdata.anrede + ';' + titel + this.customerdata.kundenname + ';'
            + ddg + ';' + this.customerdata.ortdesgespraechs + ';'
            + this.customerdata.beratername + ';' + this.customerdata.wmstandort;

        return ('combookdata||0||' + folien + '||' + kundendaten + '||' + text + '||' + agendathemen + '||');

    }

    // duplicate in commaster
    generatePPTLocation(entry: Modul, modulmode: string) {

        let path = entry.path;
        if (path === undefined) {
            path = '';
        }
        console.log("modulmodeX243X>>:" + modulmode)
        if (modulmode === 'wm_english') {
            return this.wm_english + path + entry.link;
        }
        if (modulmode === 'wm') {
            return this.wm_base + path + entry.link;
        }
        if (modulmode === 'uk') {
            return this.uk_base + path + entry.link;
        }
        if (modulmode === 'ik') {
            return this.ik_base + path + entry.link;
        }
    }

    // duplicate in commaster
    generateMandatoryPPTLocation(entry: Modul, modulmode: string) {

        let path = entry.path;
        if (path === undefined) {
            path = '';
        }
        console.log("modulmodeX265X>>:" + modulmode)
        if (modulmode === 'wm_english') {
            return this.wm_english + path + entry.mandatoryinfo;
        }
        if (modulmode === 'wm') {
            return this.wm_base + path + entry.mandatoryinfo;
        }
        if (modulmode === 'uk') {
            return this.uk_base + path + entry.mandatoryinfo;
        }
        if (modulmode === 'ik') {
            return this.ik_base + path + entry.mandatoryinfo;
        }
    }

    getFormattedDate(datum) {
        let d=new Date(datum)
        let formattedDate = (moment(d)).format('DD.MM.YYYY')
        return (formattedDate);    
    }


    // Special handling für das Glossar in der VV 
    generateGlossarLocation(modulmode: string) {

        let path = 'VV/';

        if (modulmode === 'wm') {
            return this.wm_base + path + 'VV_Glossar_1.pptx';
        }
    }

    generateStandardLocation(folie: string, modulmode: string) {
        // console.log('>>>' + modulmode);
        // IK-Standard-Folder ist der WM-Book-Standard
        console.log("modulmodeX295X>>:" + modulmode)
        if (modulmode === 'wm' || modulmode === 'ik') {
            return this.wm_base + 'Standard/' + folie;
        }
        if (modulmode === 'uk') {
            return this.uk_base + 'Standard/' + folie;
        }
        if (modulmode === 'wm_english') {
            return this.wm_english + 'Standard/' + folie;
        }
        /*
        if (modulmode === 'ik') {
            return this.ik_base + 'Standard/' + folie;
        }
        */
    }

    private getAgendaText(entry: Modul): string {

        if (entry.displayname !== undefined && entry.displayname !== '') {
            return entry.displayname;
        }
        return entry.name;
    }

    httpGet(theUrl: string): Modul[] {
        const xmlHttp = new XMLHttpRequest();
        xmlHttp.open('GET', theUrl, false);
        xmlHttp.send(null);
        return JSON.parse(xmlHttp.responseText);
    }

    generateWMModule(contacturl: string, language: string): string {
        let folien = '';
        let text = ' | ';
        let agendathemen = '';
        let kundendaten = '';

        console.log(">>>WM " + language)

        // VV
        for (const entry of this.module) {
            if (entry.id === 8) {

                var module_vv: Modul[] = this.httpGet('./assets/data/module_vv.txt');
                var einstieg = module_vv[0];
                var investment_a = module_vv[1];
                var investment_b = module_vv[2];
                var anlagestrategie: Modul = module_vv[3];
                var pricing: Modul = module_vv[4];
                var markt_a: Modul = module_vv[5];
                var markt_b: Modul = module_vv[6];
                var vorteile: Modul = module_vv[7];

                var elvis: Modul = module_vv[8];
                var weiter: Modul = module_vv[9];

                
                for (const subentry of entry.submodule) {
                    for (const subsubentry of subentry.submodule) {
                        switch (subsubentry.id) {
                            
                            case 8001001:
                                console.log("VV Beratung Einsteiger");

                                var package1: Modul[] = [];
                                var package2: Modul[] = [];
                                var package2ids = [8001001007, 8001001008, 8001001009, 8001001010];
                                for (const subsubsubentry of subsubentry.submodule) {
                                    if (package2ids.indexOf(subsubsubentry.id) > -1) {
                                        package2.push(subsubsubentry);
                                    } else {
                                        package1.push(subsubsubentry);
                                    }
                                }
                                var newmodules: Modul[] = [einstieg];

                                if (package1.length > 0) {
                                    newmodules = newmodules.concat(investment_a);
                                    newmodules = newmodules.concat(package1);
                                    newmodules = newmodules.concat(anlagestrategie);
                                    newmodules = newmodules.concat(pricing);
                                }
                                if (package2.length > 0) {
                                    newmodules = newmodules.concat(package2);
                                }
                                newmodules = newmodules.concat(markt_a);
                                newmodules = newmodules.concat(vorteile);
                                subsubentry.submodule = newmodules;
                                break;

                            case 8001002:
                                console.log("VV Beratung Profi");
                                var package1: Modul[] = [];
                                var package2: Modul[] = [];
                                var package2ids = [8001002007, 8001002008, 8001002009, 8001002010]
                                for (const subsubsubentry of subsubentry.submodule) {
                                    if (package2ids.indexOf(subsubsubentry.id) > -1) {
                                        package2.push(subsubsubentry);
                                    } else {
                                        package1.push(subsubsubentry);
                                    }
                                }
                                var newmodules: Modul[] = [einstieg];

                                if (package1.length > 0) {
                                    newmodules = newmodules.concat(investment_b);
                                    newmodules = newmodules.concat(package1);
                                    newmodules = newmodules.concat(anlagestrategie);
                                    newmodules = newmodules.concat(pricing);
                                }
                                if (package2.length > 0) {
                                    newmodules = newmodules.concat(package2);
                                }
                                newmodules = newmodules.concat(markt_b);
                                newmodules = newmodules.concat(vorteile);
                                subsubentry.submodule = newmodules;
                                break;

                            case 8002001:
                                console.log("VV Reporting Einsteiger");

                                var newmodules: Modul[] = [markt_a];
                                newmodules = newmodules.concat(subsubentry.submodule);
                                newmodules = newmodules.concat(elvis);
                                newmodules = newmodules.concat(weiter);
                                subsubentry.submodule = newmodules;
                                break;

                            case 8002002:
                                console.log("VV Reporting Profi");
                                var newmodules: Modul[] = [markt_b];
                                newmodules = newmodules.concat(subsubentry.submodule);
                                newmodules = newmodules.concat(elvis);
                                newmodules = newmodules.concat(weiter);
                                subsubentry.submodule = newmodules;
                                break;
                        }
                        
                    }
                }
                
            }
        }

        // Deckblatt
        folien = this.generateStandardLocation(this.deckblatt, 'wm') + ';';

        // Agenda
        folien = folien + this.generateStandardLocation('Agenda_1.pptx', 'wm') + ';';

        // Ausgangslage
        if (this.showTextblock > 0) {
            if(language=="en") {
                folien = folien + this.generateStandardLocation('Einstieg_en_1.pptx', 'wm') + ';';
            } else {
                folien = folien + this.generateStandardLocation('Einstieg_1.pptx', 'wm') + ';';
            }
            
            text = this.ausgangslage + ' |' + this.schwerpunkte + ' ';
        }
        // Kontakte Start
        // console.log(">" + this.kontaktfolie_position);
        if (this.kontaktfolie_position === 'start') {
            for (const entry of this.kontaktfolien) {
                folien = folien + contacturl + entry + ';';
            }
        }

        // Wenn vv, dann glossar einbinden
        let vv_selected = false;

        for (const entry of this.module) {
            if (entry.id.toString().substring(0, 1) === '8') {
                vv_selected = true;
            }
            agendathemen = agendathemen + '1|' + this.getAgendaText(entry) + ';';
            if (entry.link !== '') {
                folien = folien + this.generatePPTLocation(entry, 'wm') + ';';
            }
            for (const subentry of entry.submodule) {
                if (subentry.submodule.length > 0) {
                    agendathemen = agendathemen + '2|' + this.getAgendaText(subentry) + ';';
                }
                if (subentry.link !== '') {
                    folien = folien + this.generatePPTLocation(subentry, 'wm') + ';';
                }
                for (const subsubentry of subentry.submodule) {
                    let agendaText = '';
                    if (subsubentry.submodule.length > 0) {
                        agendaText = this.getAgendaText(subsubentry);
                        if (agendaText !== 'Einsteiger' && agendaText !== 'Profi') {
                            agendathemen = agendathemen + '3|' + agendaText + ';';
                        }
                    }
                    if (subsubentry.link !== '') {
                        folien = folien + this.generatePPTLocation(subsubentry, 'wm') + ';';
                    }
                    for (const sub3entry of subsubentry.submodule) {
                        let number = '4';
                        if (agendaText === 'Einsteiger' || agendaText === 'Profi') {
                            number = '3';
                        }
                        agendathemen = agendathemen + number + '|' + sub3entry.name + ';';
                        if (sub3entry.link !== '') {
                            folien = folien + this.generatePPTLocation(sub3entry, 'wm') + ';';
                        }
                    }
                }
            }
        }

        // Kontakte Ende
        if (this.kontaktfolie_position === 'end') {
            for (const entry of this.kontaktfolien) {
                folien = folien + contacturl + entry + ';';
            }
        }
        // Weiterempfehlung
        if (this.showWeiterempfehlungpage > 0) {
            if(language=="en") {
                folien = folien + this.generateStandardLocation('referral_1.pptx', 'wm') + ';';
            } else {
                folien = folien + this.generateStandardLocation('Empfehlung_1.pptx', 'wm') + ';';
            }
        }
        // Weitere Schritte
        if(language=="en") {
            folien = folien + this.generateStandardLocation('nextsteps_1.pptx', 'wm') + ';';
        } else {
            folien = folien + this.generateStandardLocation('SchritteX_1.pptx', 'wm') + ';';
        }

        if (vv_selected === true) {
            folien = folien + this.generateGlossarLocation('wm') + ';';
            // {id: 7015, level: 1, parent: 13, name: 'Glossar', path: 'VV/',  link: 'VV_Glossar.pptx', displayname: '', submodule: []}, 
        }

        // Disclaimer
        if (this.showAnlageberatung > 0) {
            folien = folien + this.generateStandardLocation('Disclaimer_Beratung_1.pptx', 'wm') + ';';
        } else {
            if(language=="en") {
                folien = folien + this.generateStandardLocation('Disclaimer_en_1.pptx', 'wm') + ';';
            } else {
                folien = folien + this.generateStandardLocation('Disclaimer_1.pptx', 'wm') + ';';
            }
        }

        // MandatoryInfos
        for (const entry of this.module) {
            if (entry.mandatoryinfo !== undefined && entry.mandatoryinfo !== '') {
                folien = folien + this.generateMandatoryPPTLocation(entry, 'wm') + ';';
            }
            for (const subentry of entry.submodule) {
                if (subentry.mandatoryinfo !== undefined && subentry.mandatoryinfo !== '') {
                    folien = folien + this.generateMandatoryPPTLocation(subentry, 'wm') + ';';
                }
                for (const subsubentry of subentry.submodule) {
                    if (subsubentry.mandatoryinfo !== undefined && subsubentry.mandatoryinfo !== '') {
                        folien = folien + this.generateMandatoryPPTLocation(subsubentry, 'wm') + ';';
                    }
                    for (const sub3entry of subsubentry.submodule) {
                        // agendathemen = agendathemen + '4|' + sub3entry.name + ';';
                        if (sub3entry.mandatoryinfo !== undefined && sub3entry.mandatoryinfo !== '') {
                            folien = folien + this.generateMandatoryPPTLocation(sub3entry, 'wm') + ';';
                        }
                    }
                }
            }
        }

        var ddg = '' + this.getFormattedDate(this.customerdata.datumdesgespraechs);
        if (this.customerdata.datumdesgespraechs === undefined) {
            ddg = '';
        }

        let titel = ';';
        if (this.customerdata.titel !== undefined) {
            titel = this.customerdata.titel + ';';
        }
        kundendaten = this.customerdata.anrede + ';' + titel + this.customerdata.kundenname + ';'
            + ddg + ';' + this.customerdata.ortdesgespraechs + ';'
            + this.customerdata.beratername + ';' + this.customerdata.wmstandort;

        return ('combookdata||1||' + folien + '||' + kundendaten + '||' + text + '||' + agendathemen + '||');

    }


    generateUKModule(contacturl: string): string {
        let folien = '';
        let text = ' | ';
        let agendathemen = '';
        let kundendaten = '';

        const context = 'uk';

        // Deckblatt

        folien = this.generateStandardLocation(this.deckblatt, context) + ';';

        // Agenda
        folien = folien + this.generateStandardLocation('Agenda_1.pptx', context) + ';';

        // Kontakte Start
        // console.log(">" + this.kontaktfolie_position);
        if (this.kontaktfolie_position === 'start') {
            for (const entry of this.kontaktfolien) {
                folien = folien + contacturl + entry + ';';
            }
        }
        for (const entry of this.module) {
            agendathemen = agendathemen + '1|' + this.getAgendaText(entry) + ';';
            if (entry.link !== '') {
                folien = folien + this.generatePPTLocation(entry, context) + ';';
            }
            for (const subentry of entry.submodule) {
                if (subentry.submodule.length > 0) {
                    agendathemen = agendathemen + '2|' + this.getAgendaText(subentry) + ';';
                }
                if (subentry.link !== '') {
                    folien = folien + this.generatePPTLocation(subentry, context) + ';';
                }
                for (const subsubentry of subentry.submodule) {
                    if (subsubentry.submodule.length > 0) {
                        agendathemen = agendathemen + '3|' + this.getAgendaText(subsubentry) + ';';
                    }
                    if (subsubentry.link !== '') {
                        folien = folien + this.generatePPTLocation(subsubentry, context) + ';';
                    }
                    for (const sub3entry of subsubentry.submodule) {
                        //agendathemen = agendathemen + '4|' + sub3entry.name + ';';
                        if (sub3entry.link !== '') {
                            folien = folien + this.generatePPTLocation(sub3entry, context) + ';';
                        }
                    }
                }
            }
        }
        // Kontakte Ende
        if (this.kontaktfolie_position === 'end') {
            for (const entry of this.kontaktfolien) {
                folien = folien + contacturl + entry + ';';
            }
        }

        // Weitere Schritte
        folien = folien + this.generateStandardLocation('SchritteX_1.pptx', context) + ';';

        // Weiterempfehlung
        if (this.showWeiterempfehlungpage > 0) {
            folien = folien + this.generateStandardLocation('referral_1.pptx', context) + ';';
        }

        // Disclaimer
        folien = folien + this.generateStandardLocation('Disclaimer_1.pptx', context) + ';';

        var ddg = '' + this.getFormattedDate(this.customerdata.datumdesgespraechs);
        if (this.customerdata.datumdesgespraechs === undefined) {
            ddg = '';
        }

        let titel = ';';
        if (this.customerdata.titel !== undefined) {
            titel = this.customerdata.titel + ';';
        }
        kundendaten = this.customerdata.anrede + ';' + titel + this.customerdata.kundenname + ';'
            + ddg + ';' + this.customerdata.ortdesgespraechs + ';'
            + this.customerdata.beratername + ';' + this.customerdata.wmstandort;

        return ('combookdata||1||' + folien + '||' + kundendaten + '||' + text + '||' + agendathemen + '||');

    }

    generateUKThema(contacturl: string, language: string): string {

        let folien = '';
        let agendathemen = '';
        let kundendaten = '';
        const disclaimer = '';
        const text = '';
        const context = 'uk';

        // Deckblatt
        // folien = this.generateStandardLocation('Deckblatt1.pptx', context) + ';';

        // Agenda
        // folien = folien + this.generateStandardLocation('Agenda.pptx', context) + ';';

        // Kontakte Start
        /*if (this.kontaktfolie_position === 'start') {
            for (const entry of this.kontaktfolien) {
                folien = folien + contacturl + entry + ';';
            }
        }
        */

        // console.log(this.module);
        for (const entry of this.module) {
            agendathemen = agendathemen + '1|' + entry.name + ';';
            if (entry.link !== '') {
                const tempFolie = this.generatePPTLocation(entry, context);
                folien = tempFolie + ';';
                //disclaimer = tempFolie.replace(".pptx", "_disclaimer.pptx");

            }
        }

        // Kontakte Ende
        if (this.kontaktfolie_position === 'end') {
            for (const entry of this.kontaktfolien) {
                folien = folien + contacturl + entry + ';';
            }
        }

        // Weiterempfehlung
        if (this.showWeiterempfehlungpage > 0) {
            if (language === 'english') {
                folien = folien + this.generateStandardLocation('referral_1.pptx', context) + ';';
            } else {
                folien = folien + this.generateStandardLocation('Empfehlung_1.pptx', context) + ';';
            }
        }

        // Weitere Schritte
        if (language === 'english') {
            folien = folien + this.generateStandardLocation('SchritteX_1.pptx', context) + ';'; //nextsteps_1.pptx
        } else {
            folien = folien + this.generateStandardLocation('SchritteX_1.pptx', context) + ';';
        }

        // Disclaimer
        if (language === 'english') {
            folien = folien + this.generateStandardLocation('Disclaimer_en_1.pptx', context) + ';';
        } else {
            folien = folien + this.generateStandardLocation('Disclaimer_1.pptx', context) + ';';
        }


        var ddg = '' + this.getFormattedDate(this.customerdata.datumdesgespraechs);
        if (this.customerdata.datumdesgespraechs === undefined) {
            ddg = '';
        }

        let titel = ';';
        if (this.customerdata.titel !== undefined) {
            titel = this.customerdata.titel + ';';
        }
        kundendaten = this.customerdata.anrede + ';' + titel + this.customerdata.kundenname + ';'
            + ddg + ';' + this.customerdata.ortdesgespraechs + ';'
            + this.customerdata.beratername + ';' + this.customerdata.wmstandort;

        return ('combookdata||0||' + folien + '||' + kundendaten + '||' + text + '||' + agendathemen + '||');
    }

    generateIK(language: string, contacturl: string) {

        let folien = '';
        let agendathemen = '';
        let kundendaten = '';
        let text = '-';
        const context = 'ik';

        
        // Deckblatt und Agenda
        if (language === 'de') {
            folien = folien + this.generateStandardLocation('Deckblatt_1.pptx', context) + ';';
            folien = folien + this.generateStandardLocation('Agenda_1.pptx', context) + ';';

        }
        if (language === 'en') {
            folien = folien + this.generateStandardLocation('Deckblatt_en_1.pptx', context) + ';';
            folien = folien + this.generateStandardLocation('Agenda_1.pptx', context) + ';';
        }
 
        // Kontakte Start
        if (this.kontaktfolie_position === 'start') {
            for (const entry of this.kontaktfolien) {
                folien = folien + contacturl + entry + ';';
            }
        }

        for (const entry of this.module) {
            agendathemen = agendathemen + '1|' + this.getAgendaText(entry) + ';';
            if (entry.link !== '') {
                folien = folien + this.generatePPTLocation(entry, context) + ';';
            }
            for (const subentry of entry.submodule) {
                if (subentry.submodule.length > 0) {
                    //agendathemen = agendathemen + '2|' + this.getAgendaText(subentry) + ';';
                }
                if (subentry.link !== '') {
                    folien = folien + this.generatePPTLocation(subentry, context) + ';';
                }
                for (const subsubentry of subentry.submodule) {
                    if (subsubentry.submodule.length > 0) {
                        //agendathemen = agendathemen + '3|' + this.getAgendaText(subsubentry) + ';';
                    }
                    if (subsubentry.link !== '') {
                        folien = folien + this.generatePPTLocation(subsubentry, context) + ';';
                    }
                    for (const sub3entry of subsubentry.submodule) {
                        // agendathemen = agendathemen + '4|' + sub3entry.name + ';';
                        if (sub3entry.link !== '') {
                            folien = folien + this.generatePPTLocation(sub3entry, context) + ';';
                        }
                    }
                }
            }
        }


        // Kontakte Ende
        if (this.kontaktfolie_position === 'end') {
            for (const entry of this.kontaktfolien) {
                folien = folien + contacturl + entry + ';';
            }
        }

        // Weitere Schritte
        console.log("lang: " + language)
        if (language === 'de') {
            // Weiterempfehlung
            if (this.showWeiterempfehlungpage > 0) {
                folien = folien + this.generateStandardLocation('Empfehlung_1.pptx', context) + ';';
            }
            folien = folien + this.generateStandardLocation('SchritteX_1.pptx', context) + ';';
            // Disclaimer
            folien = folien + this.generateStandardLocation('Disclaimer_1.pptx', context) + ';';
        } else {
            // Weiterempfehlung
            if (this.showWeiterempfehlungpage > 0) {
                folien = folien + this.generateStandardLocation('referral_1.pptx', context) + ';';
            }
            folien = folien + this.generateStandardLocation('nextsteps_1.pptx', context) + ';';
            // Disclaimer
            folien = folien + this.generateStandardLocation('Disclaimer_en_1.pptx', context) + ';';
        }


        /*
                } else {
                    // Deckblatt
                    folien = 'Deckblatt_en.pptx;';
        
                    // Agenda
                    folien = folien + 'Agenda.pptx;';
        
                    // Empfehlung
                    folien = folien + 'referral.pptx;';
        
                    // Kontakte Start
                    if (this.kontaktfolie_position === 'start') {
                        for (const entry of this.kontaktfolien) {
                            folien = folien + entry + ';';
                        }
                    }
                    for (const entry of this.module) {
                        folien = folien + entry.link + ';';
                    }
                    // Kontakte Ende
                    if (this.kontaktfolie_position === 'ende') {
                        for (const entry of this.kontaktfolien) {
                            folien = folien + entry + ';';
                        }
                    }
        
                    // Weitere Schritte
                    folien = folien + 'nextsteps.pptx;';
        
                    // Disclaimer
                    folien = folien + 'Disclaimer_en.pptx;';
                }
        */

        var ddg = '' + this.getFormattedDate(this.customerdata.datumdesgespraechs);
        if (this.customerdata.datumdesgespraechs === undefined) {
            ddg = '';
        }

        let titel = ';';
        if (this.customerdata.titel !== undefined) {
            titel = this.customerdata.titel + ';';
        }
        kundendaten = this.customerdata.anrede + ';' + titel + this.customerdata.kundenname + ';' + ddg + ';' + this.customerdata.ortdesgespraechs + ';' + this.customerdata.beratername + ';' + this.customerdata.wmstandort;
        console.log('combookdata||1||' + folien + '||' + kundendaten + '||' + text + '||' + agendathemen + '||');
        return ('combookdata||1||' + folien + '||' + kundendaten + '||' + text + '||' + agendathemen + '||');

    }



}
