import { Component, OnInit } from '@angular/core';
import { Mainpage } from '../models/mainpage';
import { WMService } from './wm.service';
import { GlobalEventsManager } from "../services/GlobalEventsManager";
import { ComMaster } from '../models/commaster';
import { DataService } from '../data.service';

@Component({
  selector: 'my-app',
  providers: [WMService],
  templateUrl: './wm.html',
  styleUrls: ['wm.component.css']
})


export class WMComponent extends ComMaster implements OnInit {

  title = 'Herzlich Willkommen im Wealth Management';
  subtitle = 'Ihre Unterstützung bei der Erstellung professioneller Kundenpräsentationen.';
  wmpages: Mainpage[];
  selectedMainpage: Mainpage;

  constructor(private wmService: WMService, private globalEventsManager: GlobalEventsManager, public dataService: DataService) {
    super(dataService);

    // console.log('WMComponent constructor ' + this.context + '-' + this.modulemode);

    this.storeContext('wm');
    //this.globalEventsManager.showNavBar('wm');
    this.dataService.setNavState(this.context);
    

    // console.log('/WMComponent constructor ' + this.context + '-' + this.modulemode);

  }

  getWMpages(): void {
    this.wmService.getWMpages().then(mainpages => this.wmpages = mainpages);
  }

  ngOnInit(): void {

    // console.log('WMComponent ngOnInit ' + this.context + '-' + this.modulemode);

    localStorage.setItem('modulewm_module', '');
    this.getWMpages();
    // console.log('/WMComponent ngOnInit ' + this.context + '-' + this.modulemode);

  }

  onSelect(mainpage: Mainpage): void {
    this.selectedMainpage = mainpage;
  }

  reloadData(mainpage: Mainpage) {
    localStorage.setItem('reload', 'yes');
    this.dataService.setModules("MAINPAGE", mainpage)
  }
}



