import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Modul } from './models/modul';
import { NavComponent } from './nav.component';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private storage: Storage) { }
  
  language: string
  auswahl: Modul[]

  navComponent:NavComponent

  navState: string = "wm"
  
  public init() {
    this.storage.create();
  }

  public getFullState() {
    return this.storage.get("LANGUAGE").then((lang)=>{
      this.language = lang

      return this.storage.get("AUSWAHL").then((auswahl)=>{
        this.auswahl = auswahl
      })
    })
  }


  public async getModules(name:string) {
    return this.storage.get(name)
  }

  public setModules(name, toSave) {
    
    return this.storage.set(name, toSave).then((_) => {
      console.log(name + " written")
      console.log(toSave)
      
    });
    
  }

  public registerNavComponent(navComponent:NavComponent) {
    this.navComponent = navComponent
  }

  public getNavState() {
    console.log("getNavState " + this.navState)
    return this.navState
  }

  public setNavState(navState: string) {
    console.log("setNavState " + navState)
    if (navState!="") {
      this.navState = navState
      this.navComponent.navChanged()  
    }
  }
}
