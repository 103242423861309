export const environment = {
  production: false,

  startpath: 'wealthmanagement',

  envname: 'stage',

  version: '5.1.10',
  pptname: 'combook151.xlsm',
  ppt365: 'combook_365.xlsm',

  baseHref: '',
  deckblattfolder: '/assets/images/deckblattbilder/',
  startImagePath: '/assets/images/',
  docurl: '/docbase/',

  contacturl: 'h:\\team\\',

  wm_base: '/',
  wm_english: '/Englisch/',
  uk_base: '/GKBOOK/Inhalt/',
  ik_base: '/WMbook/Module/IK/'

}
