import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/platform-browser';
import { GlobalEventsManager} from '../services/GlobalEventsManager';
import { Customerdata } from '../models/customerdata';
import { Modul } from '../models/modul';
import { KundeService } from '../kunde/kunde.service';
import { SharedService } from '../services/shared.service';
import { ComMaster } from '../models/commaster';
import { Metadata } from '../models/metadata';
import { Clipboard } from 'ts-clipboard';
import { Combook } from '../models/combook';
import { DataService } from '../data.service';

@Component({
  selector: 'my-app',
  providers: [KundeService],
  templateUrl: 'kunde.html',
  styleUrls: ['kunde.component.css']
})


export class KundeComponent extends ComMaster implements OnInit{

    title = 'Kunden- und Beraterdaten';
    subtitle = 'Vor der Erstellung der gewünschten Unterlage überprüfen und ergänzen Sie bitte folgende Angaben:';

    pmode: string;

    customerdata: Customerdata = new Customerdata;
    selectionArray: Modul[] = [];
    caller = '';
    arguments = 'x';

    standort: string;

    team: string[] = [];                // bereits ausgewählte Folien zur Auswahl
    teamfolienSelected: string[] = [];  // angeklickte Folien

    deckblaetter: string[] = [];
    deckblatt: string;
    showDeckblaetter = 1;

    showWeiterempfehlungpage: number = -1;
    weiterempfehlungsCheck = this.url + this.baseHref + "/" + this.leererCheck;

    showAnlageberatung: number = -1;
    showAnlageberatungCheckbox: number = -1;

    showContactpage: number = -1;
    showContactCheck = this.url + this.baseHref + "/" + this.leererCheck;
    contactStartCheck = this.url + this.baseHref + "/" + this.gelberCheck;
    contactEndCheck = this.url + this.baseHref + "/" + this.leererCheck;
    showContactPositionStart = this.url + this.baseHref + "/" + this.leererCheck;
    showContactPositionEnd = this.url + this.baseHref + "/" + this.leererCheck;
    showContactpositionChooser = 1;
    contactPageAt = 'end';

    anreden = ['', 'Herrn', 'Frau', 'Mr.', 'Mrs.'];

    titel = ['', 'Prof. Dr.', 'Prof.', 'Dr.'];

    constructor(public dataService: DataService, @Inject(DOCUMENT) private document, private sharedService: SharedService) {
        super(dataService);
        this.url = document.location.protocol + '//' + document.location.host;
    }

    ngOnInit() {

        this.context = this.getContext();
        console.log("context " + this.context);
        this.caller = this.getCaller();
        //this.globalEventsManager.showNavBar(this.context);
        this.dataService.setNavState(this.context);
    
        this.team = this.getTeamfolien();
        this.modulemode = this.getModulemode();
        console.log(this.context);
        let metadata: Metadata = Metadata.getMetadata(this.getContext());
        // console.log(metadata);
        this.customerdata = metadata.customerdata;
        this.showWeiterempfehlungpage = metadata.showWeiterempfehlungpage;
        this.showContactpage = metadata.showContactpage;
        this.teamfolienSelected = metadata.kontaktfolien;
        this.showAnlageberatung = metadata.showAnlageberatung;
        this.contactPageAt = metadata.kontaktfolie_position;
        this.selectionArray = JSON.parse(localStorage.getItem('currentagenda'));
        this.customerdata = metadata.customerdata;
        this.showContactpositionChooser = 1;
        //this.kontaktfolie_position = metadata.kontaktfolie_position;
        //metadata.kontaktfolien = t.kontaktfolien;
        console.log(metadata);

        //
        // DRINGEND REFACTOREN!!!
        //

        this.pmode = this.getCurrentPresentationMode();
        // console.log(this.pmode)
        if (this.showWeiterempfehlungpage > 0) {
            this.weiterempfehlungsCheck = this.url + this.baseHref + "/" + this.gelberCheck;
        }

        if (this.showContactpage > 0) {
            this.showContactCheck = this.url + this.baseHref + "/" + this.gelberCheck;
        }

        // console.log(this.contactPageAt)
        if (this.contactPageAt === 'start') {
            this.contactPageAt = 'start';
            this.showContactPositionStart = this.url + this.baseHref + "/" + this.gelberCheck;
        }
        if (this.contactPageAt === 'end' || this.contactPageAt === '' || this.contactPageAt === undefined) {
            this.contactPageAt = 'end';
            this.showContactPositionEnd = this.url + this.baseHref + "/" + this.gelberCheck;
        }

        if (this.modulemode === this.UK_MODULE || this.modulemode === this.PB_MODULE) {
            this.standort = 'Standort';
        } else {
            this.standort = 'Wealth Management Standort';
        }

        if (this.modulemode === this.WM_MODULE || this.modulemode === this.UK_MODULE || this.modulemode === this.PB_MODULE) {
            this.deckblatt = metadata.deckblatt;
            
            // ("deck1:" + this.deckblatt);
            if (this.deckblatt === null || this.deckblatt === undefined || this.deckblatt === '') {
                // console.log("1");
                this.deckblatt = 'Deckblatt1_1.pptx';
                metadata.deckblatt = this.deckblatt;
                metadata.storeMetadata(this.context);
            }
            this.deckblaetter = this.getDeckblaetter();
        }
        if (this.modulemode === this.WM_MODULE) {
            this.showAnlageberatungCheckbox = -1;
        }

        // console.log("mm>" + this.modulemode);
        if ((this.modulemode === this.WM_MODULE || this.modulemode === this.UK_MODULE )  && this.pmode === 'thema') {
            this.showContactpositionChooser = 0;
        }

        if (this.pmode === 'thema' || this.pmode === 'english') {
            this.showAnlageberatungCheckbox = -1;
            this.showDeckblaetter = -1;
        }

        if (this.modulemode === this.WM_ENGLISH || this.modulemode === this.UK_ENGLISH) {
            this.showContactpositionChooser = 0;
            this.showAnlageberatungCheckbox = -1;
            this.showDeckblaetter = -1;
        }

        if (this.pmode==='modul') {
            this.showDeckblaetter = 1;
            this.deckblaetter = this.getDeckblaetter();

            console.log("this.pmode")
            console.log(this.pmode)
            console.log("---->>MODUL----")
        }

                
        this.arguments = metadata.getMetadataAsVBAString(this.context, this.getLanguage(), this.pmode, this.docurl, this.contacturl);
        // console.log(this.arguments);
    }

    storeCustomerData() {
        this.storeCustomerdataTemp();
    }




    change(event: any) {

        // console.log(event.target.files);
        for (let f of event.target.files) {
            // console.log(f)
            if (!this.team.includes(f.name)) {
                this.team.push(f.name)
            }
            this.storeTeamfolien(this.team)
        }
        //this.metadata.kontaktfolien = this.teamfolienSelected
    }


    switchDeckblatttoggle(image: any, clickedtoggle: string, twins: string[]): void {
        let metadata: Metadata = Metadata.getMetadata(this.context);
        this.switchMultitoggle(image, clickedtoggle, twins);
        metadata.deckblatt = clickedtoggle;
        metadata.storeMetadata(this.context);

        this.arguments = metadata.getMetadataAsVBAString(this.context, this.getLanguage(), this.pmode, this.docurl, this.contacturl);
    }

    getDeckblaetter() {
        console.log("getDeckblätter for " + this.getLanguage())
        const deckblaetter = [];
        if (this.getLanguage()==='de') {
            deckblaetter.push('Deckblatt1_1.pptx');
            deckblaetter.push('Deckblatt2_1.pptx');
            deckblaetter.push('Deckblatt3_1.pptx');
        }
        if (this.getLanguage()==='en') {
            deckblaetter.push('Deckblatt4_1.pptx');
            deckblaetter.push('Deckblatt5_1.pptx');
            deckblaetter.push('Deckblatt6_1.pptx');
        }
        console.log(deckblaetter)
        return deckblaetter;
    }

    public isDeckblattChecked(deckblattCheck: string): String {

        if (this.deckblatt === deckblattCheck) {
            return this.url + this.baseHref + "/" + this.gelberCheck;
        } else {
            return this.url + this.baseHref + "/" + this.leererCheck;
        }
    }

    getDeckblattThumb(deckblatt: string) {
       
        // || this.modulemode === 'english_module'
        let url=''
        if (this.modulemode === this.UK_MODULE || this.modulemode === this.WM_MODULE || this.modulemode === this.PB_MODULE|| this.modulemode === 'english_module' || this.modulemode === this.IK_MODULE || this.modulemode === 'ik_module_en' || this.modulemode === 'ik_module_de') { 
            url = this.url + this.deckblattfolder + deckblatt.replace('.pptx','').replace('.ppt','') + ".png";
        } 
        /*else if (this.modulemode === this.UK_MODULE) {
            url = this.url + '/GKBOOK/Inhalt/' + this.deckblattfolder + deckblatt.replace('.pptx','').replace('.ppt','') + ".jpg";
        } */
        
        /*else if (this.modulemode === this.IK_MODULE || this.modulemode === 'ik_module_en' || this.modulemode === 'ik_module_de') {
            url = this.url + '/WMbook/Module/IK/' + this.deckblattfolder + deckblatt.replace('.pptx','').replace('.ppt','') + ".png";
        }
        */
       
        console.log("-------getDeckblattThumb-----")
        console.log(this.modulemode)
        console.log(url)
        return url;
    }

    teamfolienLoeschen() {
        this.team = [];
        this.storeTeamfolien(this.team);
    }

    public isTeamfolieChecked(teamfolie: string): String {

        if (this.teamfolienSelected!=undefined && this.teamfolienSelected.includes(teamfolie)) {
          return this.url + this.baseHref + "/" + this.gelberCheck;
        } else {
          return this.url + this.baseHref +  "/" + this.leererCheck;
        }
      }

    public isChecked(check: number): String {
        if (check > 0) {
            return this.url + this.baseHref + "/" + this.gelberCheck;
        } else {
            return this.url + this.baseHref + "/" + this.leererCheck;
        }
    }



    simpleToggle(image: any): void {

        if (image.src === this.url + this.baseHref + "/" + this.leererCheck) {
            image.src = this.url + this.baseHref + "/" + this.gelberCheck;
        } else {
            image.src = this.url + this.baseHref + "/" + this.leererCheck;
        }

        this.storeCustomerdataTemp();
    }


    contactToggle(image: any): void {

        // console.log("ct");
        if (this.showContactpage === 0) {
            this.showContactpage = -1;
        }
        this.showContactpage *= -1;
        this.simpleToggle(image);

        let metadata: Metadata = Metadata.getMetadata(this.context);
        metadata.kontaktfolien = this.teamfolienSelected;
        metadata.storeMetadata(this.context);

        //this.metadata.kontaktfolien = this.teamfolienSelected

        this.storeCustomerdataTemp();

        this.arguments = metadata.getMetadataAsVBAString(this.context, this.getLanguage(), this.pmode, this.docurl, this.contacturl);
        // console.log('contactToggle ' + this.showContactpage + ' ' + this.showContactpositionChooser);

    }


    teamToggle(image:any, teamfolie:string): void {

        // console.log("tt");
        if (this.teamfolienSelected==undefined) {
            this.teamfolienSelected = [];
        }
        if (this.teamfolienSelected.includes(teamfolie)) {
            this.removeStringFromStringarray(teamfolie, this.teamfolienSelected);
        } else {
            this.teamfolienSelected.push(teamfolie);
        }

        this.simpleToggle(image);

        let metadata: Metadata = Metadata.getMetadata(this.context);
        metadata.kontaktfolien = this.teamfolienSelected
        metadata.storeMetadata(this.context);

    }

    weiterempfehlungsToggle(image:any): void {

        this.showWeiterempfehlungpage *= -1;
        this.simpleToggle(image);

        let metadata: Metadata = Metadata.getMetadata(this.context);
        metadata.showWeiterempfehlungpage = this.showWeiterempfehlungpage;
        metadata.storeMetadata(this.context);

        this.arguments = metadata.getMetadataAsVBAString(this.context, this.getLanguage(), this.pmode, this.docurl, this.contacturl);
        //this.metadata.showWeiterempfehlungpage = this.showWeiterempfehlungpage;
    }

    anlageberatungsToggle(image: any): void {

        this.showAnlageberatung *= -1;
        this.simpleToggle(image);

        let metadata: Metadata = Metadata.getMetadata(this.context);
        metadata.showAnlageberatung = this.showAnlageberatung;
        metadata.storeMetadata(this.context);

        this.arguments = metadata.getMetadataAsVBAString(this.context, this.getLanguage(), this.pmode, this.docurl, this.contacturl);
        // this.metadata.showAnlageberatung = this.showAnlageberatung;
    }

    radioToggle(defButton: string): void {

        const image_1 = (<HTMLImageElement>document.getElementById('radio1'));
        const image_2 = (<HTMLImageElement>document.getElementById('radio2'));

        if (this.contactPageAt === 'start') {
            this.contactPageAt = 'end';
            image_1.src = this.url + this.baseHref + "/" + this.leererCheck;
            image_2.src = this.url + this.baseHref + "/" + this.gelberCheck;
        } else {
            this.contactPageAt = 'start';
            image_1.src = this.url + this.baseHref + "/" + this.gelberCheck;
            image_2.src = this.url + this.baseHref + "/" + this.leererCheck;
        }

        this.storeCustomerdataTemp()

    }

    storeCustomerdataTemp(): void {

        //console.log('store customerdata ' + this.customerdata)
        this.storeKundendaten(JSON.stringify(this.customerdata));

        let metadata = Metadata.getMetadata(this.context);
        if (metadata!=undefined) {
            metadata.customerdata = this.customerdata;
            metadata.showWeiterempfehlungpage = this.showWeiterempfehlungpage;
            metadata.showAnlageberatung = this.showAnlageberatung;
            metadata.showContactpage = this.showContactpage;
            metadata.kontaktfolien = this.teamfolienSelected;
            metadata.kontaktfolie_position = this.contactPageAt;
            metadata.storeMetadata(this.context);
        } else {
            // console.log('metadata undefined')
        }

        this.arguments = metadata.getMetadataAsVBAString(this.context, this.getLanguage(), this.pmode, this.docurl, this.contacturl);

    }

    loadCustomerdataTemp(): void {
        //console.log('load customerdata')
        this.customerdata = JSON.parse(this.getKundendaten());

    }

    createpresentation(): void {
        this.storeCustomerdataTemp;
        this.loadCustomerdataTemp;
        const metadata = Metadata.getMetadata(this.context);

        const args = metadata.getMetadataAsVBAString(this.context, this.getLanguage(), this.pmode, this.docurl, this.contacturl);
            
        Clipboard.copy(args);
        // console.log(args);
        window.open(this.docurl + this.pptname, '_blank');
    }

    createpresentation365(): void {
        this.storeCustomerdataTemp;
        this.loadCustomerdataTemp;
        const metadata = Metadata.getMetadata(this.context);

        const args = metadata.getMetadataAsVBAString(this.context, this.getLanguage(), this.pmode, this.docurl, this.contacturl);
        Clipboard.copy(args);
        console.log(args);
        window.open(this.docurl + this.pptname_365, '_blank');
    }

    copyTo() {
        Clipboard.copy('HalliHallo');
        //console.log('metadata copied to clipboard ')
    }

    goBack() {
        window.history.back();
    }

}



