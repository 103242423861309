import { Injectable } from '@angular/core';
import { Mainpage } from '../models/mainpage';
import { ENGLISHPAGES } from '../definitions/englishpages';

@Injectable()
export class EnglishService {
  getWMpages(): Promise<Mainpage[]> {
    return Promise.resolve(ENGLISHPAGES);
  }
}
