import { Component, Input } from '@angular/core';
import { ComMaster } from './models/commaster';
import { DataService } from './data.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'my-nav',
    providers: [],
    templateUrl: './nav.component.html',
})

export class NavComponent extends ComMaster {
    @Input() isActive: string;

    showNavBar = '';

    startpath = ''

    constructor(public dataService: DataService) {
        super(dataService);
        console.log("NavState " + this.showNavBar)
        this.dataService.registerNavComponent(this)
        this.showNavBar = this.dataService.getNavState()
        this.startpath = environment.startpath
    }

    public navChanged() {
        console.log("NavState: navChanged")
        this.showNavBar = this.dataService.getNavState()
    }

    public showMainNav() {
        this.showNavBar = "main"
        this.dataService.setNavState(this.showNavBar)
        this.context = ('')
        this.storeContext('')

    }

    reloadData() {
        console.log("NavState: reloadData")
        localStorage.setItem('reload', 'yes');
        this.showNavBar = this.dataService.getNavState()
    }

    setModulbase(modus:string):void {
        console.log("NavState: setModulbase " + modus)
        this.modulemode = modus
        this.storeModulemode(modus);
    }
}
