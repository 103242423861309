import { Component, OnInit } from '@angular/core';
import { Mainpage } from '../models/mainpage';
import { IKService } from './ik.service';
import { ComMaster } from '../models/commaster';
import { GlobalEventsManager} from "../services/GlobalEventsManager";
import { DataService } from '../data.service';

@Component({
  selector: 'my-app',
  providers: [IKService],
  templateUrl: './ik.html',
  styleUrls: ['ik.component.css']
    
})


export class IKComponent extends ComMaster implements OnInit {

  title = 'Internationale Kunden';
  subtitle = "Ihre Unterstützung bei der Erstellung professioneller Kundenpräsentationen.";
  ikpages: Mainpage[];
  selectedMainpage: Mainpage;

  constructor(private ikService: IKService, public dataService: DataService) {
    super(dataService);

    // console.log('IKComponent constructor ' + this.context + '-' + this.modulemode);

    this.storeContext('ik');
    //this.globalEventsManager.showNavBar('ik');
    this.dataService.setNavState('ik');
    

    // console.log('/IKComponent constructor ' + this.context + '-' + this.modulemode);
  }

  getIKpages(): void {
    this.ikService.getIKpages().then(mainpages => this.ikpages = mainpages);
  }

  ngOnInit(): void {
    // console.log('IKComponent ngOnInit ' + this.context + '-' + this.modulemode);

    this.getIKpages();

    // console.log('/IKComponent ngOnInit ' + this.context + '-' + this.modulemode);
  }

  onSelect(mainpage: Mainpage): void {
    this.selectedMainpage = mainpage;
  }

  reloadData() {
    localStorage.setItem('reload', 'yes');
  }
}



