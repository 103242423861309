import { Injectable } from '@angular/core';
import { Mainpage } from '../models/mainpage';
import { MAINPAGES } from '../definitions/mainpages';

@Injectable()
export class MainpageService {
  getMainpages(): Promise<Mainpage[]> {
    return Promise.resolve(MAINPAGES);
  }
}
