import { Injectable } from '@angular/core';
import { ComMaster } from '../models/commaster';

import { Modul } from '../models/modul';
import { Thema } from '../models/thema';
import { Submodul } from '../models/submodul';

import { MODULE_WM } from '../definitions/module_wm';
import { MODULE_WM_EN } from '../definitions/module_wm_en';
import { MODULE_GK } from '../definitions/module_gk';
import { MODULE_IK_EN } from '../definitions/module_ik_en';
import { MODULE_IK_DE } from '../definitions/module_ik_de';

import { SUBMODULE } from '../definitions/submodule';
import { Observable } from 'rxjs';

import { ModulpageComponent } from './modulpage/modulpage.component';
import { Mainpage } from '../models/mainpage';
import { DataService } from '../data.service';



@Injectable()
export class ModulpageService extends ComMaster {

  constructor(public dataService: DataService) {
    super(dataService);
  }

  getModule(modulemode: Mainpage): Modul[] {

    console.log('MPS: getModule ' + modulemode.ctx);
   // let temp: Modul[] = [];

      console.log('Load Modules for ' + modulemode.ctx + " " + modulemode.lang)

      if (modulemode.ctx=="EnglishBook" && modulemode.lang=="en") {
        this.load(this.ENGLISH_MODULE);
        return this.english_assets;
      }
      
      if (this.getLanguage() === 'de') {
      
        console.log('Loading for DE')
      
        if (modulemode.ctx == 'WMbook') {
          console.log('Load WMbook')
          this.load('wm_module');
          this.storeLanguage('de')
          return this.wm_assets;
        }

        if (modulemode.ctx === 'UKbook') {
          this.load('uk_module');
          return this.uk_assets;
        }

        if (modulemode.ctx === 'IKbook') {
          this.load('ik_module');
          return this.ik_assets;
        }

        if (modulemode.linktitle == 'PBbook') {
          this.load('pb_module');
          return this.pb_de_assets;
        }


      } else {

        console.log('Loading for EN')


        if (modulemode.ctx == 'IKbook') {
          this.load('ik_en_module');
          return this.ik_en_assets;
        }

        if (modulemode.ctx == 'EnglishBook') {
          this.load(this.ENGLISH_MODULE);
          return this.english_assets;
        }
      }
    

  }


  getThemen(context: string, subcontext: string): Thema[] {

    console.log('getThemen>' + context);
    if (context === 'wm') {
      if (subcontext === 'uk_themen_en') {
        this.load('wm_en_module');
        return this.wm_en_assets;
      } else {
        this.load('wm_themen');
        return this.wm_themen_assets;
      }
    }

    if (context === 'uk') {

      if (subcontext === 'uk_themen_en') {
        this.load('uk_themen_en');
        return this.uk_themen_en_assets;
      }
      if (subcontext === 'uk_themen') {
        this.load('uk_themen');
        return this.uk_themen_assets;
      }


    }

  }



}
