import { Injectable } from '@angular/core';
import { Mainpage } from '../models/mainpage';
import { WMPAGES } from '../definitions/wmpages';

@Injectable()
export class WMService {
  getWMpages(): Promise<Mainpage[]> {
    return Promise.resolve(WMPAGES);
  }
}
