import { Injectable } from '@angular/core';
import { Mainpage } from '../models/mainpage';
import { IKPAGES } from '../definitions/ikpages';

@Injectable()
export class IKService {
  getIKpages(): Promise<Mainpage[]> {
    return Promise.resolve(IKPAGES);
  }
}
